
.logo2-wrapper img {

    
    width:80px;
    margin-bottom: 12px;
    float: left;
    justify-content: left;
    padding-right: 0%
  
}